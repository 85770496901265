// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-management {
    margin: 20px;
  }
  
  .file-upload, .manual-entry-form {
    margin: 10px 0;
  }
  
  .manual-entry-form label {
    display: block;
    margin: 5px 0;
  }
  
  .feedback-message.success {
    color: green;
  }
  
  .feedback-message.error {
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Student.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,aAAa;EACf;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".student-management {\n    margin: 20px;\n  }\n  \n  .file-upload, .manual-entry-form {\n    margin: 10px 0;\n  }\n  \n  .manual-entry-form label {\n    display: block;\n    margin: 5px 0;\n  }\n  \n  .feedback-message.success {\n    color: green;\n  }\n  \n  .feedback-message.error {\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
