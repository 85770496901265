// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignments-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  
  .filters label {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .filters input {
    padding: 5px;
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .assignments-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .assignments-table th, .assignments-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .assignments-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TeacherAssignments.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":[".assignments-container {\n    max-width: 800px;\n    margin: auto;\n    padding: 20px;\n  }\n  \n  .filters {\n    display: flex;\n    gap: 20px;\n    margin-bottom: 20px;\n    justify-content: space-between;\n  }\n  \n  .filters label {\n    font-weight: bold;\n    margin-right: 5px;\n  }\n  \n  .filters input {\n    padding: 5px;\n    width: 100px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .assignments-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .assignments-table th, .assignments-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .assignments-table th {\n    background-color: #f2f2f2;\n    text-align: left;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
