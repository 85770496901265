import React, { useEffect, useState } from "react";

const FetchTeachers = () => {
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTeachers();
    }, []);

    const fetchTeachers = async () => {
        try {
            const response = await fetch("https://tms.up.school/api/get-teachers");
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || "Failed to fetch teachers");
            }

            setTeachers(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchTeacherDetails = async (teacherId) => {
        try {
            const response = await fetch(`https://tms.up.school/api/get-teacher-details/${teacherId}`);
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.error || "Failed to fetch teacher details");
            }
    
            console.log("Fetched Teacher Details:", data); // Debugging line
    
            // ✅ Ensure the JSON fields are parsed properly
            setSelectedTeacher({
                ...data,
                teacher_info: typeof data.teacher_info === "string" ? JSON.parse(data.teacher_info) : data.teacher_info,
                teacher_section_allocation: typeof data.teacher_section_allocation === "string" ? JSON.parse(data.teacher_section_allocation) : data.teacher_section_allocation,
                school_list: typeof data.school_list === "string" ? JSON.parse(data.school_list) : data.school_list,
            });
        } catch (err) {
            setError(err.message);
        }
    };
    

    return (
        <div>
            <h2>Stored Teachers List</h2>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}

            <table border="1">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>School ID</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {teachers.map((teacher) => (
                        <tr key={teacher.teacher_id}>
                            <td>{teacher.teacher_id}</td>
                            <td>{teacher.user_firstname}</td>
                            <td>{teacher.user_lastname}</td>
                            <td>{teacher.user_email}</td>
                            <td>{teacher.user_phone_no || "N/A"}</td>
                            <td>{teacher.school_id}</td>
                            <td>{teacher.user_status}</td>
                            <td>
                                <button onClick={() => fetchTeacherDetails(teacher.teacher_id)}>
                                    View Details
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Show Teacher Details */}
            {selectedTeacher && (
                <div style={{
                    border: "1px solid black",
                    padding: "15px",
                    marginTop: "20px",
                    backgroundColor: "#f9f9f9"
                }}>
                    <h3>Teacher Details</h3>
                    <p><strong>Teacher ID:</strong> {selectedTeacher.teacher_id}</p>

                    <h4>Sections</h4>
                    <table border="1" width="100%">
                        <thead>
                            <tr>
                                <th>Allocation ID</th>
                                <th>Class ID</th>
                                <th>Section ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedTeacher.teacher_section_allocation.length > 0 ? (
                                selectedTeacher.teacher_section_allocation.map((allocation, index) => (
                                    <tr key={index}>
                                        <td>{allocation.allocation_id}</td>
                                        <td>{allocation.client_class_id}</td>
                                        <td>{allocation.section_id}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="3">No section allocations available</td></tr>
                            )}
                        </tbody>
                    </table>

                    <h4>Subjects</h4>
                    <table border="1" width="100%">
                        <thead>
                            <tr>
                                <th>Subject ID</th>
                                <th>Class ID</th>
                                <th>Section ID</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedTeacher.teacher_info.length > 0 ? (
                                selectedTeacher.teacher_info.map((subject, index) => (
                                    <tr key={index}>
                                        <td>{subject.subject_id}</td>
                                        <td>{subject.client_class_id}</td>
                                        <td>{subject.section_id}</td>
                                        <td>{subject.info_status}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="4">No subjects assigned</td></tr>
                            )}
                        </tbody>
                    </table>

                    <h4>Schools</h4>
                    <table border="1" width="100%">
                        <thead>
                            <tr>
                                <th>School ID</th>
                                <th>School Name</th>
                                <th>Board</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedTeacher.school_list.length > 0 ? (
                                selectedTeacher.school_list.map((school, index) => (
                                    <tr key={index}>
                                        <td>{school.school_id}</td>
                                        <td>{school.school_name}</td>
                                        <td>{school.school_board}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="3">No school information available</td></tr>
                            )}
                        </tbody>
                    </table>

                    <button onClick={() => setSelectedTeacher(null)}>Close</button>
                </div>
            )}
        </div>
    );
};

export default FetchTeachers;
