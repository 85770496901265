// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-container {
  padding: 20px;
}

.request-table-container {
  margin-top: 20px;
}

.request-table {
  width: 100%;
  border-collapse: collapse;
}

.request-table th, .request-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.request-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.request-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.request-table tbody tr:hover {
  background-color: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/components/MRequest.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".request-container {\n  padding: 20px;\n}\n\n.request-table-container {\n  margin-top: 20px;\n}\n\n.request-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.request-table th, .request-table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.request-table th {\n  background-color: #f2f2f2;\n  text-align: left;\n}\n\n.request-table tbody tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.request-table tbody tr:hover {\n  background-color: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
