import React from 'react';

const MViewActivities = () => {
  return (
    <div>
      <h2>View Activities</h2>
      <p>View and track all activities here.</p>
    </div>
  );
};

export default MViewActivities;
