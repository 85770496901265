// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Attendance.css */

.attendance-management {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .attendance-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .attendance-table th,
  .attendance-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .attendance-table th {
    background-color: #f2f2f2;
  }
  
  .save-button {
    margin-top: 15px;
    padding: 8px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  .absent {
    color: red;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Attendance.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;IACI,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,UAAU;IACV,iBAAiB;EACnB","sourcesContent":["/* src/components/Attendance.css */\n\n.attendance-management {\n    margin: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .attendance-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .attendance-table th,\n  .attendance-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .attendance-table th {\n    background-color: #f2f2f2;\n  }\n  \n  .save-button {\n    margin-top: 15px;\n    padding: 8px 15px;\n    background-color: #28a745;\n    color: white;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .save-button:hover {\n    background-color: #218838;\n  }\n  .absent {\n    color: red;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
