// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  text-align: center;
  padding: 20px;
}

.gif-container {
  width: 100%;
  overflow: hidden;
}

.full-page-gif {
  width: 100%;
  height: auto;
}

.welcome-message {
  margin-top: 20px;
  font-size: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/MDashboard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".dashboard-container {\n  text-align: center;\n  padding: 20px;\n}\n\n.gif-container {\n  width: 100%;\n  overflow: hidden;\n}\n\n.full-page-gif {\n  width: 100%;\n  height: auto;\n}\n\n.welcome-message {\n  margin-top: 20px;\n  font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
