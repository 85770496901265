import React, { useEffect, useState } from "react";
import axios from "axios";
import './recommendations.css';

const Recommendations = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchTeachers();
  }, []);

  // Fetch all teachers and their existing recommendations
  const fetchTeachers = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://tms.up.school/api/all-teachers-recommendations");
      setTeachers(response.data.data);
    } catch (err) {
      setError("Failed to fetch teachers and recommendations.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch new recommendations for ALL teachers
  const fetchAllRecommendations = async () => {
    setFetching(true);
    try {
      const teacherIds = teachers.map((teacher) => teacher.teacher_id);

      await Promise.all(
        teacherIds.map(async (teacherId) => {
          await axios.get(`https://tms.up.school/api/fetch-recommendations/${teacherId}`);
        })
      );

      // Refresh data after fetching recommendations
      await fetchTeachers();
    } catch (err) {
      console.error("Error fetching new recommendations:", err);
      setError("Failed to fetch new recommendations.");
    } finally {
      setFetching(false);
    }
  };

  if (loading) return <p className="text-center text-gray-600">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-center mb-6 text-black">Teachers & Recommendations</h2>

      <div className="flex justify-center mb-6">
        <button
          onClick={fetchAllRecommendations}
          className="bg-[#0d6efd] text-white font-semibold py-2 px-6 rounded-lg shadow-lg transition duration-200 disabled:bg-gray-400"
          disabled={fetching}
        >
          {fetching ? "Updating All Recommendations..." : "Fetch Latest Recommendations for All"}
        </button>
      </div>

      {teachers.length === 0 ? (
        <p className="text-center text-gray-500">No teachers have recommendations yet.</p>
      ) : (
        <div className="overflow-x-auto shadow-lg rounded-lg">
          <table className="min-w-full border border-gray-300 bg-white text-black">
            <thead className="bg-gray-900 text-white">
              <tr>
                <th className="border border-gray-400 px-6 py-3 text-left text-lg">School ID</th>
                <th className="border border-gray-400 px-6 py-3 text-left text-lg">Teacher ID</th>
                <th className="border border-gray-400 px-6 py-3 text-left text-lg">Recommendations</th>
                <th className="border border-gray-400 px-6 py-3 text-center text-lg">Details</th>
              </tr>
            </thead>
            <tbody>
              {teachers.map((teacher, index) => (
                <tr key={teacher.teacher_id} className={index % 2 === 0 ? "bg-gray-100" : "bg-Black"}>
                  <td className="border border-gray-300 px-6 py-3">{teacher.school_id}</td>
                  <td className="border border-gray-300 px-6 py-3">{teacher.teacher_id}</td>
                  <td className="border border-gray-300 px-6 py-3">
                    {teacher.recommendations.length > 0 ? (
                      <ul className="list-disc pl-5">
                        {teacher.recommendations.map((rec, index) => (
                          <li key={index}>
                            <span className="font-semibold">{rec.concept}</span> - {rec.topic}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-gray-500 italic">No recommendations available.</p>
                    )}
                  </td>
                  <td className="border border-gray-300 px-6 py-3 text-center">
                    <button className="bg-[#0d6efd] text-white font-semibold py-1 px-3 rounded shadow-md hover:bg-[#0b5ed7] transition">
                      Show Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Recommendations;
