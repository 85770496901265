// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tests-management {
    margin-top: 20px;
  }
  
  .tests-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .tests-table th,
  .tests-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tests-table th {
    background-color: #e9ecef;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Tests.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB","sourcesContent":[".tests-management {\n    margin-top: 20px;\n  }\n  \n  .tests-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 10px;\n  }\n  \n  .tests-table th,\n  .tests-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .tests-table th {\n    background-color: #e9ecef;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
