import React, { useEffect, useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useManagerAuth } from '../context/ManagerAuthContext';
import './MClassroom.css';

const MClassroom = () => {
  const { managerId, token } = useManagerAuth();

  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedClassName, setSelectedClassName] = useState('');
  const [selectedSectionName, setSelectedSectionName] = useState('');
  const [classSectionSubjectMap, setClassSectionSubjectMap] = useState([]);
  const [availableBoards, setAvailableBoards] = useState([]);

  const navigate = useNavigate();

  // Fetch all schools assigned to the manager
  useEffect(() => {
    if (managerId && token) fetchSchools();
  }, [managerId, token]);

  const fetchSchools = async () => {
    try {
      const response = await axiosInstance.get(`/managers/${managerId}/schools`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSchools(response.data);
    } catch (error) {
      console.error('Error fetching schools:', error.message);
    }
  };

  // When school is selected, fetch its board and related class info
  useEffect(() => {
    if (selectedSchool) {
      fetchFetchedClassInfo(selectedSchool);
    }
  }, [selectedSchool]);
  
  useEffect(() => {
    if (classSectionSubjectMap.length > 0) {
      // Extract unique boards from the fetched class info
      const boards = [
        ...new Set(
          classSectionSubjectMap
            .map((item) => {
              try {
                return JSON.parse(item.board); // ["CBSE"]
              } catch (err) {
                console.error('Failed to parse board:', item.board);
                return [];
              }
            })
            .flat()
        ),
      ];
      setAvailableBoards(boards);
      if (boards.length > 0) setSelectedBoard(boards[0]);
    }
  }, [classSectionSubjectMap]);
  

  const fetchFetchedClassInfo = async (schoolId) => {
    try {
      const response = await axiosInstance.get(`/classinfo/get-fetched-classinfo/${schoolId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClassSectionSubjectMap(response.data);
    } catch (error) {
      console.error('Error fetching fetched class info:', error.message);
    }
  };

  // Unique classes from the fetched class info
  const uniqueClasses = [...new Set(
    classSectionSubjectMap
      .filter(item => {
        try {
          const parsed = JSON.parse(item.board);
          return parsed.includes(selectedBoard);
        } catch (e) {
          return false;
        }
      })
      .map(item => item.upschool_class_name)
  )];

  const sectionsForClass = [...new Set(
    classSectionSubjectMap
      .filter(item => {
        try {
          const parsed = JSON.parse(item.board);
          return (
            item.upschool_class_name === selectedClassName &&
            parsed.includes(selectedBoard)
          );
        } catch (e) {
          return false;
        }
      })
      .map(item => item.section_name)
  )];

  const subjectsForSection = classSectionSubjectMap.filter(item => {
    try {
      const parsed = JSON.parse(item.board);
      return (
        item.upschool_class_name === selectedClassName &&
        item.section_name === selectedSectionName &&
        parsed.includes(selectedBoard)
      );
    } catch (e) {
      return false;
    }
  });

  const handleSectionSelect = () => {
    const sectionData = subjectsForSection[0];
    if (!sectionData) return;

    navigate(`/dashboard/school/${sectionData.school_id}/class/${sectionData.client_class_id}/section/${sectionData.section_id}`, {
      state: {
        selectedSchool: sectionData.school_id,
        selectedClass: sectionData.client_class_id,
        selectedSection: sectionData.section_id,
        fetchedClassInfoList: subjectsForSection,
      },
    });
    
  };

  return (
    <div className="container">
      <div className="classroom-container">
        <h1>Select Board, School, Class, and Section</h1>

        {/* School Dropdown */}
        <div className="form-group">
          <label>School:</label>
          <select value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
            <option value="" disabled>Select School</option>
            {schools.map((school) => (
              <option key={school.school_id} value={school.school_id}>
                {school.school_name}
              </option>
            ))}
          </select>
        </div>

        {/* Board Dropdown */}
        <div className="form-group">
          <label>Board:</label>
          <select
            value={selectedBoard}
            onChange={(e) => setSelectedBoard(e.target.value)}
            disabled={!availableBoards.length}
          >
            <option value="" disabled>Select Board</option>
            {availableBoards.map((board, index) => (
              <option key={index} value={board}>{board}</option>
            ))}
          </select>
        </div>

        {/* Class Dropdown */}
        <div className="form-group">
          <label>Class:</label>
          <select
            value={selectedClassName}
            onChange={(e) => {
              setSelectedClassName(e.target.value);
              setSelectedSectionName('');
            }}
            disabled={!selectedBoard}
          >
            <option value="" disabled>Select Class</option>
            {uniqueClasses.map((cls, index) => (
              <option key={index} value={cls}>{cls}</option>
            ))}
          </select>
        </div>

        {/* Section Dropdown */}
        <div className="form-group">
          <label>Section:</label>
          <select
            value={selectedSectionName}
            onChange={(e) => setSelectedSectionName(e.target.value)}
            disabled={!selectedClassName}
          >
            <option value="" disabled>Select Section</option>
            {sectionsForClass.map((sec, index) => (
              <option key={index} value={sec}>{sec}</option>
            ))}
          </select>
        </div>

        {/* Subjects */}
        <div>
          <h3>Subjects:</h3>
          {subjectsForSection.length > 0 ? (
            subjectsForSection.map((subject, index) => (
              <div key={index} className="subject-item">
                {subject.subject_title || 'No Subject Name'}
              </div>
            ))
          ) : (
            <p>No subjects found for this section.</p>
          )}
        </div>

        <button
          onClick={handleSectionSelect}
          disabled={!selectedSectionName}
          className="select-button"
        >
          Select Section
        </button>

        {/* Debug Panel */}
        <pre style={{ background: '#f0f0f0', padding: '10px', marginTop: '20px' }}>
          {JSON.stringify({
            selectedSchool,
            selectedBoard,
            availableBoards,
            selectedClassName,
            selectedSectionName,
            uniqueClasses,
            sectionsForClass,
            subjectsForSection
          }, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default MClassroom;
